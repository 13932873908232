import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/activity-list'
  },
  {
    path: '/test',
    name: 'test',
    meta: {
      title: '测试',
      showTab: true
    },
    component: () => import('@/views/test.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title: '注册用户',
      showTab: false
    },
    component: () => import('@/views/user/register.vue')
  },
  {
    path: '/switchover',
    name: 'switchover',
    meta: {
      title: '切换学员',
      showTab: false
    },
    component: () => import('@/views/user/switchover.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    meta: {
      title: '',
      showTab: false
    },
    component: () => import('@/views/user/agreement.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      showTab: false
    },
    component: () => import('@/views/user/login.vue')
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      title: '搜索'
    },
    component: () => import('@/views/searchDemo.vue')
  },
  {
    path: '/password',
    name: 'password',
    meta: {
      title: '忘记密码',
      showTab: false
    },
    component: () => import('@/views/user/password.vue')
  },
  {
    path: '/activity-list',
    name: 'activityList',
    meta: {
      title: '活动列表',
      showTab: true
    },
    component: () => import('@/views/activity/index.vue')
  },
  {
    path: '/activity-detail',
    name: 'activityDetail',
    meta: {
      title: '活动详情',
      showTab: false
    },
    component: () => import('@/views/activity/detail.vue')
  },
  {
    path: '/activity-subscribe',
    name: 'activitySubscribe',
    meta: {
      title: '活动预约',
      showTab: false
    },
    component: () => import('@/views/activity/subscribemake.vue')
  },
  {
    path: '/evaluate',
    name: 'evaluate',
    meta: {
      title: '自律评价',
      showTab: false
    },
    component: () => import('@/views/user/selfevaluate.vue')
  },
  {
    path: '/evaluaterule',
    name: 'evaluaterule',
    meta: {
      title: '评分细则',
      showTab: false
    },
    component: () => import('@/views/user/evaluaterule.vue')
  },
  {
    path: '/user',
    name: 'user',
    meta: {
      title: '会员中心',
      showTab: true
    },
    component: () => import('@/views/user/index.vue')
  },
  {
    path: '/history',
    name: 'history',
    meta: {
      title: '搜索历史',
      showTab: false
    },
    component: () => import('@/views/user/history.vue')
  },
  {
    path: '/person',
    name: 'person',
    meta: {
      title: '个人资料',
      showTab: false
    },
    component: () => import('@/views/user/person.vue')
  },
  {
    path: '/honor',
    name: 'honor',
    meta: {
      title: '我的荣誉',
      showTab: false
    },
    component: () => import('@/views/user/honor.vue')
  },
  {
    path: '/honordetail',
    name: 'honordetail',
    meta: {
      title: '勋章规则',
      showTab: false
    },
    component: () => import('@/views/user/honordetail.vue')
  },
  {
    path: '/purse',
    name: 'purse',
    meta: {
      title: '账户营元',
      showTab: false
    },
    component: () => import('@/views/user/purse.vue')
  },
  {
    path: '/recharge',
    name: 'recharge',
    meta: {
      title: '充值',
      showTab: false
    },
    component: () => import('@/views/user/recharge.vue')
  },
  {
    path: '/volunteerservice',
    name: 'volunteerservice',
    meta: {
      title: '我的志愿服务',
      showTab: false
    },
    component: () => import('@/views/user/volunteerservice.vue')
  },
  {
    path: '/servicedetail',
    name: 'servicedetail',
    meta: {
      title: '志愿服务详情',
      showTab: false
    },
    component: () => import('@/views/user/servicedetail.vue')
  },
  {
    path: '/serviceevaluate',
    name: 'serviceevaluate',
    meta: {
      title: '志愿服务评价',
      showTab: false
    },
    component: () => import('@/views/user/serviceevaluate.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    meta: {
      title: '常见问题',
      showTab: false
    },
    component: () => import('@/views/user/faq.vue')
  },
  {
    path: '/faqdetail',
    name: 'faqdetail',
    meta: {
      title: '常见问题',
      showTab: false
    },
    component: () => import('@/views/user/faqdetail.vue')
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: '关于我们',
      showTab: false
    },
    component: () => import('@/views/user/about.vue')
  },
  {
    path: '/phonebind',
    name: 'phonebind',
    meta: {
      title: '绑定手机号',
      showTab: false
    },
    component: () => import('@/views/user/phonebind.vue')
  },
  {
    path: '/phoneupdate',
    name: 'phoneupdate',
    meta: {
      title: '更换手机号',
      showTab: false
    },
    component: () => import('@/views/user/phoneupdate.vue')
  },
  {
    path: '/help',
    name: 'help',
    meta: {
      title: '帮助中心',
      showTab: true
    },
    component: () => import('@/views/notice/index.vue')
  },
  {
    path: '/help-detail',
    name: 'help-detail',
    meta: {
      title: '帮助详情',
      showTab: false
    },
    component: () => import('@/views/notice/detail.vue')
  },
  {
    path: '/order',
    name: 'order',
    meta: {
      title: '订单列表',
      showTab: true
    },
    component: () => import('@/views/order/index.vue')
  },
  {
    path: '/orderdetail',
    name: 'orderdetail',
    meta: {
      title: '订单详情',
      showTab: false
    },
    component: () => import('@/views/order/detail.vue')
  },
  {
    path: '/orderrefund',
    name: 'orderrefund',
    meta: {
      title: '退款详情',
      showTab: false
    },
    component: () => import('@/views/order/refund.vue')
  },
  {
    path: '/orderpay',
    name: 'orderpay',
    meta: {
      title: '收银台',
      showTab: false
    },
    component: () => import('@/views/order/pay.vue')
  },
  {
    path: '/ordersuccess',
    name: 'ordersuccess',
    meta: {
      title: '收银台',
      showTab: false
    },
    component: () => import('@/views/order/success.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: '/',
  routes
})

// router.beforeEach((to, from, next) => {
//   // console.log('token:', mapState.token)
//   document.title = to.meta.title
//   next()
// })
// router.afterEach((to, from, next) => {

//   next()
// })

export default router
