const data = {
  token: '',
  imageBaseUsl: 'https://5scamp.yuanren.ltd/api',
  userInfo: null,
  themeMedalList: [],
  baseMedalList: [],
  searchData: {},
  certTypeList: [{
      label: '身份证',
      value: '1'
    },
    {
      label: '外籍护照',
      value: '2'
    },
    {
      label: '驾驶证',
      value: '3'
    },
  ],
}
export default {
  data
}
